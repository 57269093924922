































import { Component, Vue } from "vue-property-decorator";
import { IdAndName } from "../../models/interfaces/IdAndName";

@Component
export default class TableSelectableItem extends Vue {
    private items: { [key: number]: IdAndName; } = {};
    private values: { id: number, name: string }[] = [];
    private isShow = false;
    private resolve?: (value?: number | PromiseLike<number> | undefined) => void = undefined;
    private search = "";

    public showAsync(items: { [key: number]: IdAndName; }, x: number, y: number, values: { id: number, name: string }[] = []): Promise<number | undefined> {
        if (values.length) {
            this.values = values;
        }

        this.isShow = true;
        this.items = items;
        this.$nextTick(() => {
            this.moveDialog(x, y);
        });
        // eslint-disable-next-line no-return-assign
        return new Promise<number | undefined>(resolve => this.resolve = resolve);
    }

    private selected(selectedId: number): void {
        this.isShow = false;
        this.items = {};
        this.values = [];
        if (this.resolve) {
            this.resolve(selectedId);
        }
    }

    private moveDialog(x: number, y: number): void {
        const dialog = this.$refs.vList as Vue;
        if (!dialog) {
            return;
        }

        const element = dialog.$el as HTMLElement;
        if (!element) {
            return;
        }

        // 横座標を補正する
        // x + 要素幅が画面端を超えるなら、画面幅 - 要素幅を設定
        if (x + element.offsetWidth + 32 > window.innerWidth) {
            x = window.innerWidth - element.offsetWidth - 32;
        }

        // 縦座標を補正する
        // y + 要素高が画面端を超えるなら、y - 要素高を設定
        if (y + element.offsetHeight > window.innerHeight) {
            y = y - element.offsetHeight - 24;
        }

        element.style.top = y + "px";
        element.style.left = x + "px";
    }

    private cancel(): void {
        if (!this.resolve) return;
        this.isShow = false;
        this.items = {};
        this.values = [];
        this.resolve(undefined);
    }
}
