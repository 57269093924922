




















import { Component, Vue } from "vue-property-decorator";

@Component
export default class TableDatePickerDialog extends Vue {
    private date = "";
    private defaultDate = "";
    private isShow = false;
    private resolve?: (value?: string | PromiseLike<string> | undefined) => void = undefined;

    public showAsync(defaultDate: string, x: number, y: number): Promise<string | undefined> {
        this.isShow = true;
        this.date = this.defaultDate = defaultDate;
        this.$nextTick(() => {
            this.moveDialog(x, y);
        });
        // eslint-disable-next-line no-return-assign
        return new Promise<string | undefined>(resolve => this.resolve = resolve);
    }

    private selected(): void {
        this.isShow = false;
        if (!this.resolve) {
            return;
        }

        if (this.isChange()) {
            this.resolve(this.date);
        }
    }

    private cancel(): void {
        if (!this.resolve) return;
        this.isShow = false;
        this.resolve(undefined);
    }

    private isChange(): boolean {
        return this.date !== this.defaultDate;
    }

    private moveDialog(x: number, y: number): void {
        const dialog = this.$refs.vDatePicker as Vue;
        if (!dialog) {
            return;
        }

        const element = dialog.$el as HTMLElement;
        if (!element) {
            return;
        }

        // 横座標を補正する
        // x + 要素幅が画面端を超えるなら、画面幅 - 要素幅を設定
        if (x + element.offsetWidth + 32 > window.innerWidth) {
            x = window.innerWidth - element.offsetWidth - 32;
        }

        // 縦座標を補正する
        // y + 要素高が画面端を超えるなら、y - 要素高を設定
        if (y + element.offsetHeight > window.innerHeight) {
            y = y - element.offsetHeight - 24;
        }

        element.style.top = y + "px";
        element.style.left = x + "px";
    }
}
