




































import { Component, Vue } from "vue-property-decorator";

@Component
export default class TableTextFieldDialog extends Vue {
    private isShow = false;
    private content = "";
    private defaultContent = "";
    private resolve?: (value: string | PromiseLike<string | undefined> | undefined) => void = undefined;

    public showAsync(defaultContent: string, x: number, y: number): Promise<string | undefined> {
        if (defaultContent.length > 0) {
            defaultContent = defaultContent.trim();
        }
        this.content = defaultContent;
        this.defaultContent = defaultContent;
        this.isShow = true;


        this.$nextTick(() => {
            const dialog = this.$refs.vdialog as Vue;
            if (!dialog) return;

            const element = dialog.$el as HTMLElement;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            // 横座標を補正する
            // x + 要素幅が画面端を超えるなら、画面幅 - 要素幅を設定
            x = this.min(x, windowWidth - 300);
            element.style.left = x + "px";

            // 縦座標を補正する
            // y + 要素高が画面端を超えるなら、y - 要素高を設定
            y = this.min(y, windowHeight - 60);
            element.style.top = y + "px";
        });

        // eslint-disable-next-line no-return-assign
        return new Promise<string | undefined>(resolve => this.resolve = resolve);
    }

    private submit(event: KeyboardEvent): void {
        if (event.keyCode !== 13 || event.shiftKey === false) {
            return;
        }

        this.close();
    }

    private close(): void {
        if (!this.resolve) {
            return;
        }

        this.isShow = false;

        if (this.isChange()) {
            this.resolve(this.content);
        }
    }

    private cancel(): void {
        if (!this.resolve) return;
        this.isShow = false;
        this.resolve(undefined);
    }

    private isChange(): boolean {
        return this.content !== this.defaultContent;
    }

    private max(a: number, b: number) {
        return a < b ? b : a;
    }

    private min(a: number, b: number) {
        return a < b ? a : b;
    }
}
